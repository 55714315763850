<template>
  <div class="self-procurement-register">
    <!-- 表单搜索 start -->
    <div class="form-wrap">
      <div class="seach">
        <div class="inputs">
          <el-input
            v-model="formData.product_name"
            placeholder="登记商品名称"
            clearable
          ></el-input>
        </div>
        <div class="inputs">
          <el-input
            v-model="formData.business_user_name"
            placeholder="登记员姓名"
            clearable
          ></el-input>
        </div>
        <div class="inputs">
          <el-input
            v-model="formData.receiver_name"
            placeholder="收货人姓名"
            clearable
          ></el-input>
        </div>
        <div class="inputs">
          <el-input
            v-model="formData.receiver_mobile"
            placeholder="收货人电话"
            clearable
          ></el-input>
        </div>
        <div class="inputs" v-if="role == 'tdc'">
          <el-select
            v-model="formData.delivery_id"
            filterable
            clearable
            placeholder="请选择自提点"
          >
            <el-option
              :key="item.id"
              v-for="item in arrList"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </div>
        <div class="inputs">
          <el-input
            v-model="formData.self_supply_no"
            placeholder="自采品编码"
            clearable
          ></el-input>
        </div>
        <div class="inputs">
          <el-select v-model="formData.qc_status" clearable placeholder="状态">
            <el-option label="全部" value="0"></el-option>
            <el-option label="未复核" value="10"></el-option>
            <el-option label="已复核" value="20"></el-option>
            <el-option label="已扣款" value="30"></el-option>
          </el-select>
        </div>
        <div class="inputs">
          <el-date-picker
            v-model="pay_at"
            :time-arrow-control="true"
            type="daterange"
            format="yyyy-MM-dd"
            value-format="yyyy-MM-dd"
            range-separator="—"
            start-placeholder="登记时间始"
            end-placeholder="登记时间止"
          >
          </el-date-picker>
        </div>
        <div class="inputs">
          <el-select
            v-model="formData.source"
            clearable
            placeholder="请选择来源"
          >
            <el-option label="全部" value="0"></el-option>
            <el-option label="线上" value="2"></el-option>
            <el-option label="线下" value="1"></el-option>
          </el-select>
        </div>
        <div class="inputs">
          <el-select
            v-model="formData.status"
            clearable
            placeholder="请选择登记状态"
          >
            <el-option label="已失效" value="1"></el-option>
            <el-option label="正常" value="0"></el-option>
          </el-select>
        </div>
        <div class="inputs">
          <el-button
            type="primary"
            icon="el-icon-refresh"
            class="refresh"
            @click="onHandleRefresh"
          ></el-button>
          <el-button @click="onSearch" type="primary" icon="el-icon-search"
            >查询</el-button
          >
          <el-button
            type="warning"
            @click="exportHandle"
            icon="el-icon-download"
            :disabled="!tableData.length || disabledExport"
            >导 出</el-button
          >
        </div>
      </div>
    </div>
    <!-- 表单搜索 end -->

    <!-- 列表 start -->
    <div class="table-wrap">
      <el-table
        :data="tableData"
        height="100%"
        v-loading="loading"
        :border="true"
        :header-cell-style="{ color: '#333333', background: '#EFF6FF' }"
      >
        <el-table-column
          prop="self_supply_no"
          align="center"
          label="自采品编码"
        >
        </el-table-column>
        <el-table-column prop="logistics_name" align="center" label="城市仓">
        </el-table-column>
        <el-table-column prop="delivery_name" align="center" label="自提点">
        </el-table-column>
        <el-table-column prop="receiver_name" align="center" label="收货人姓名">
        </el-table-column>
        <el-table-column
          prop="receiver_mobile"
          align="center"
          label="收货人电话"
        >
        </el-table-column>
        <el-table-column
          prop="product_name"
          align="center"
          label="登记商品名称"
        >
        </el-table-column>
        <el-table-column prop="create_time" align="center" label="登记时间">
        </el-table-column>
        <el-table-column prop="weight" align="center" label="登记重量（斤）">
        </el-table-column>
        <el-table-column
          prop="qc_weight"
          align="center"
          label="复核确认重量（斤）"
        >
        </el-table-column>
        <el-table-column prop="product_num" align="center" label="登记件数">
        </el-table-column>
        <el-table-column
          prop="qc_product_num"
          align="center"
          label="复核确认件数"
        >
        </el-table-column>
        <el-table-column
          prop="freight_amount"
          align="center"
          label="服务费金额"
        >
        </el-table-column>
        <el-table-column prop="qc_status" align="center" label="状态">
          <template slot-scope="scope">
            <span v-if="scope.row.qc_status == 10"> 未复核</span>
            <span v-else-if="scope.row.qc_status == 20">已复核</span>
            <span v-else-if="scope.row.qc_status == 30">已扣款</span>
          </template>
        </el-table-column>
        <el-table-column prop="qc_status" align="center" label="登记状态">
          <template slot-scope="scope">
            <span v-if="scope.row.status == 0"> 正常</span>
            <span v-else-if="scope.row.status == 1">已失效</span>
            <!-- <span v-else-if="scope.row.qc_status == 30">已扣款</span> -->
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!-- 列表 end -->

    <!-- 分页 start -->
    <div class="fy">
      <el-pagination
        type="primary"
        background
        @size-change="onHandleSizeChange"
        @current-change="onHandleCurrentChange"
        :current-page="formData.page"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="formData.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
  </div>
</template>
<script>
import { BASE } from "@/api";
import {
  postSelfPurchaseList,
  postSelfPurchaseExport,
} from "@/api/customPit.js";
import { downLoadFlowBlob } from "@/utils/tools/base";
export default {
  name: "self-procurement-list",
  components: {},
  data() {
    return {
      role: sessionStorage.getItem("manageIsdentity"),
      arrList: [], // 自提点列表
      loading: false,
      pay_at: "",
      formData: {
        delivery_id: "", //		是	自提点id
        receiver_name: "", //		是	收货人姓名
        receiver_mobile: "", //		是	收货人电话
        product_name: "", //		是	商品名称
        start_time: "", //		是	开始时间
        end_time: "", //		是	结束时间
        self_supply_no: "", //		是	SPR202405201816109846W8X7PBB自采品编号
        business_user_name: "",
        qc_status: "", //	是	状态
        status: "", //	是	状态
        source: "",
        page: 1,
        pageSize: 10,
      }, // 表单参数
      tableData: [], // 城市仓列表
      total: 0,
      disabledExport: false,
    };
  },
  mounted() {
    this.onInitData();
  },
  methods: {
    // 获取自提点
    getDeliveryList() {
      this.$api.delivery.deliverylist().then((res) => {
        console.log(res, "获取数据");
        this.arrList = res.data;
      });
    },
    onInitData() {
      console.log(this.role, "eee");
      if (this.role == "tdc") {
        this.getDeliveryList();
      }
      this.hqlist();
    },
    hqtime() {
      if (this.pay_at != null) {
        if (this.pay_at.length > 0) {
          this.formData.start_time = this.pay_at[0];
          this.formData.end_time = this.pay_at[1];
        } else {
          this.formData.start_time = "";
          this.formData.end_time = "";
        }
      } else {
        this.formData.start_time = "";
        this.formData.end_time = "";
      }
    },
    hqlist() {
      this.hqtime();
      this.loading = true;
      if (
        this.role == "delivery" &&
        this.formData.hasOwnProperty("delivery_id")
      ) {
        delete this.formData.delivery_id;
      }
      postSelfPurchaseList(this.formData).then((res) => {
        this.loading = false;
        this.tableData = res.data.data;
        this.total = res.data.total;
      });
    },
    /* 导出 */
    async exportHandle() {
      this.hqtime();
      this.disabledExport = true;
      this.$message.success("正在导出，请耐心等待");
      try {
        const params = {
          ...this.formData,
        };
        delete params.page;
        delete params.pageSize;
        const res = await postSelfPurchaseExport(params);
        downLoadFlowBlob(res, res?.filename);
        this.$message.success("导出成功");
      } catch (err) {
        this.$message.error("导出失败了");
        console.log("ajax postSelfPurchaseExport err", err);
      } finally {
        this.disabledExport = false;
      }
    },
    /**
     * 重置表单，并刷新
     */
    onHandleRefresh() {
      Object.assign(this.$data.formData, this.$options.data().formData);
      this.pay_at = [];
      this.hqlist();
    },
    /**
     * 搜索表单
     */
    onSearch() {
      this.formData.page = 1;
      this.hqlist();
    },
    /**
     * 每页条数发生改变
     */
    onHandleSizeChange(val) {
      this.formData.pageSize = val;
      this.hqlist();
    },
    /**
     * 页码发生改变
     */
    onHandleCurrentChange(val) {
      this.formData.page = val;
      this.hqlist();
    },
  },
};
</script>
<style lang="scss">
.position {
  position: relative;
}
.from-wraps {
  /** 修改element基础样式 */
  .el-select .el-input.is-disabled .el-input__inner {
    color: #333333;
  }

  .el-input.is-disabled .el-input__inner {
    color: #333333;
  }
}
.position-subset {
  position: absolute;
  right: -20%;
  top: 38%;
  .el-icon-circle-plus {
    font-size: 45px;
  }
  .el-icon-delete-solid {
    font-size: 45px;
  }
}
.self-procurement-register {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  height: 100%;
  box-sizing: border-box;
  padding: 10px;
  .showImg {
    text-align: center;
    height: 76vh;
  }
  .form-wrap {
    background: #ffffff;
    padding: 13px 15px;
    box-sizing: border-box;
    display: flex;
    .seach {
      flex: 1;
      display: flex;
      //   justify-content: flex-end;
      display: flex;
      flex-wrap: wrap;
      gap: 10px;
      .inputs {
        // width: 207px;
        // margin-right: 8px;
      }
    }
    .refresh {
      background: #333333;
      border-color: #333333;
    }
  }
  .table-wrap {
    flex: 1;
    overflow-y: hidden;
    .text-btn {
      color: $theme-color;
      cursor: pointer;
    }
    .el-button {
      &--text {
        padding: 10px 0;
      }
    }
  }
  .fy {
    box-sizing: border-box;
    padding: 10px 20px;
  }
  .contents {
    padding-left: 55px;
    padding-right: 55px;
    padding-top: 23px;
  }
  .base-margin {
    margin: 0 10px;
    white-space: nowrap;
  }
  .hide {
    .el-upload--picture-card {
      display: none;
    }
  }
}
</style>
