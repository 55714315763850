var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "self-procurement-register" }, [
    _c("div", { staticClass: "form-wrap" }, [
      _c("div", { staticClass: "seach" }, [
        _c(
          "div",
          { staticClass: "inputs" },
          [
            _c("el-input", {
              attrs: { placeholder: "登记商品名称", clearable: "" },
              model: {
                value: _vm.formData.product_name,
                callback: function ($$v) {
                  _vm.$set(_vm.formData, "product_name", $$v)
                },
                expression: "formData.product_name",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "inputs" },
          [
            _c("el-input", {
              attrs: { placeholder: "登记员姓名", clearable: "" },
              model: {
                value: _vm.formData.business_user_name,
                callback: function ($$v) {
                  _vm.$set(_vm.formData, "business_user_name", $$v)
                },
                expression: "formData.business_user_name",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "inputs" },
          [
            _c("el-input", {
              attrs: { placeholder: "收货人姓名", clearable: "" },
              model: {
                value: _vm.formData.receiver_name,
                callback: function ($$v) {
                  _vm.$set(_vm.formData, "receiver_name", $$v)
                },
                expression: "formData.receiver_name",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "inputs" },
          [
            _c("el-input", {
              attrs: { placeholder: "收货人电话", clearable: "" },
              model: {
                value: _vm.formData.receiver_mobile,
                callback: function ($$v) {
                  _vm.$set(_vm.formData, "receiver_mobile", $$v)
                },
                expression: "formData.receiver_mobile",
              },
            }),
          ],
          1
        ),
        _vm.role == "tdc"
          ? _c(
              "div",
              { staticClass: "inputs" },
              [
                _c(
                  "el-select",
                  {
                    attrs: {
                      filterable: "",
                      clearable: "",
                      placeholder: "请选择自提点",
                    },
                    model: {
                      value: _vm.formData.delivery_id,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "delivery_id", $$v)
                      },
                      expression: "formData.delivery_id",
                    },
                  },
                  _vm._l(_vm.arrList, function (item) {
                    return _c("el-option", {
                      key: item.id,
                      attrs: { label: item.name, value: item.id },
                    })
                  }),
                  1
                ),
              ],
              1
            )
          : _vm._e(),
        _c(
          "div",
          { staticClass: "inputs" },
          [
            _c("el-input", {
              attrs: { placeholder: "自采品编码", clearable: "" },
              model: {
                value: _vm.formData.self_supply_no,
                callback: function ($$v) {
                  _vm.$set(_vm.formData, "self_supply_no", $$v)
                },
                expression: "formData.self_supply_no",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "inputs" },
          [
            _c(
              "el-select",
              {
                attrs: { clearable: "", placeholder: "状态" },
                model: {
                  value: _vm.formData.qc_status,
                  callback: function ($$v) {
                    _vm.$set(_vm.formData, "qc_status", $$v)
                  },
                  expression: "formData.qc_status",
                },
              },
              [
                _c("el-option", { attrs: { label: "全部", value: "0" } }),
                _c("el-option", { attrs: { label: "未复核", value: "10" } }),
                _c("el-option", { attrs: { label: "已复核", value: "20" } }),
                _c("el-option", { attrs: { label: "已扣款", value: "30" } }),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "inputs" },
          [
            _c("el-date-picker", {
              attrs: {
                "time-arrow-control": true,
                type: "daterange",
                format: "yyyy-MM-dd",
                "value-format": "yyyy-MM-dd",
                "range-separator": "—",
                "start-placeholder": "登记时间始",
                "end-placeholder": "登记时间止",
              },
              model: {
                value: _vm.pay_at,
                callback: function ($$v) {
                  _vm.pay_at = $$v
                },
                expression: "pay_at",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "inputs" },
          [
            _c(
              "el-select",
              {
                attrs: { clearable: "", placeholder: "请选择来源" },
                model: {
                  value: _vm.formData.source,
                  callback: function ($$v) {
                    _vm.$set(_vm.formData, "source", $$v)
                  },
                  expression: "formData.source",
                },
              },
              [
                _c("el-option", { attrs: { label: "全部", value: "0" } }),
                _c("el-option", { attrs: { label: "线上", value: "2" } }),
                _c("el-option", { attrs: { label: "线下", value: "1" } }),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "inputs" },
          [
            _c(
              "el-select",
              {
                attrs: { clearable: "", placeholder: "请选择登记状态" },
                model: {
                  value: _vm.formData.status,
                  callback: function ($$v) {
                    _vm.$set(_vm.formData, "status", $$v)
                  },
                  expression: "formData.status",
                },
              },
              [
                _c("el-option", { attrs: { label: "已失效", value: "1" } }),
                _c("el-option", { attrs: { label: "正常", value: "0" } }),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "inputs" },
          [
            _c("el-button", {
              staticClass: "refresh",
              attrs: { type: "primary", icon: "el-icon-refresh" },
              on: { click: _vm.onHandleRefresh },
            }),
            _c(
              "el-button",
              {
                attrs: { type: "primary", icon: "el-icon-search" },
                on: { click: _vm.onSearch },
              },
              [_vm._v("查询")]
            ),
            _c(
              "el-button",
              {
                attrs: {
                  type: "warning",
                  icon: "el-icon-download",
                  disabled: !_vm.tableData.length || _vm.disabledExport,
                },
                on: { click: _vm.exportHandle },
              },
              [_vm._v("导 出")]
            ),
          ],
          1
        ),
      ]),
    ]),
    _c(
      "div",
      { staticClass: "table-wrap" },
      [
        _c(
          "el-table",
          {
            directives: [
              {
                name: "loading",
                rawName: "v-loading",
                value: _vm.loading,
                expression: "loading",
              },
            ],
            attrs: {
              data: _vm.tableData,
              height: "100%",
              border: true,
              "header-cell-style": { color: "#333333", background: "#EFF6FF" },
            },
          },
          [
            _c("el-table-column", {
              attrs: {
                prop: "self_supply_no",
                align: "center",
                label: "自采品编码",
              },
            }),
            _c("el-table-column", {
              attrs: {
                prop: "logistics_name",
                align: "center",
                label: "城市仓",
              },
            }),
            _c("el-table-column", {
              attrs: {
                prop: "delivery_name",
                align: "center",
                label: "自提点",
              },
            }),
            _c("el-table-column", {
              attrs: {
                prop: "receiver_name",
                align: "center",
                label: "收货人姓名",
              },
            }),
            _c("el-table-column", {
              attrs: {
                prop: "receiver_mobile",
                align: "center",
                label: "收货人电话",
              },
            }),
            _c("el-table-column", {
              attrs: {
                prop: "product_name",
                align: "center",
                label: "登记商品名称",
              },
            }),
            _c("el-table-column", {
              attrs: {
                prop: "create_time",
                align: "center",
                label: "登记时间",
              },
            }),
            _c("el-table-column", {
              attrs: {
                prop: "weight",
                align: "center",
                label: "登记重量（斤）",
              },
            }),
            _c("el-table-column", {
              attrs: {
                prop: "qc_weight",
                align: "center",
                label: "复核确认重量（斤）",
              },
            }),
            _c("el-table-column", {
              attrs: {
                prop: "product_num",
                align: "center",
                label: "登记件数",
              },
            }),
            _c("el-table-column", {
              attrs: {
                prop: "qc_product_num",
                align: "center",
                label: "复核确认件数",
              },
            }),
            _c("el-table-column", {
              attrs: {
                prop: "freight_amount",
                align: "center",
                label: "服务费金额",
              },
            }),
            _c("el-table-column", {
              attrs: { prop: "qc_status", align: "center", label: "状态" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      scope.row.qc_status == 10
                        ? _c("span", [_vm._v(" 未复核")])
                        : scope.row.qc_status == 20
                        ? _c("span", [_vm._v("已复核")])
                        : scope.row.qc_status == 30
                        ? _c("span", [_vm._v("已扣款")])
                        : _vm._e(),
                    ]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: { prop: "qc_status", align: "center", label: "登记状态" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      scope.row.status == 0
                        ? _c("span", [_vm._v(" 正常")])
                        : scope.row.status == 1
                        ? _c("span", [_vm._v("已失效")])
                        : _vm._e(),
                    ]
                  },
                },
              ]),
            }),
          ],
          1
        ),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "fy" },
      [
        _c("el-pagination", {
          attrs: {
            type: "primary",
            background: "",
            "current-page": _vm.formData.page,
            "page-sizes": [10, 20, 50, 100],
            "page-size": _vm.formData.pageSize,
            layout: "total, sizes, prev, pager, next, jumper",
            total: _vm.total,
          },
          on: {
            "size-change": _vm.onHandleSizeChange,
            "current-change": _vm.onHandleCurrentChange,
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }